import {createContext, useEffect, useState} from "react";
import AmogeServer from "./AmogeServer";

export const AmogeSessionContext = createContext<AmogeSession<AmogeSessionInfo>>(undefined);

export type AmogeSessionInfo = {
   userTypes: string[],
   roles: string[],
   serverName: string,
   app: string,
   component: string,
   stream: string,
   timestamp: string,
   env: {
      code: string,
      name: string,
      production: boolean
   }
}

const emptyInfo: AmogeSessionInfo = {
   userTypes: [],
   roles: [],
   serverName: "",
   app: "",
   component: "",
   stream: "",
   timestamp: "",
   env: {
      code: "",
      name: "",
      production: false
   }
}

export type AmogeSession<T extends AmogeSessionInfo> = {
   info: T,
   ready: boolean,
   amogeLoggedIn: boolean,
   reload: () => void,
   userCanDo: (role: string) => boolean,
   userCanDoAll: (roles: string[]) => boolean,
   userCanDoAny: (roles: string[]) => boolean
}

export function useAmogeSession<T extends AmogeSessionInfo>(): AmogeSession<T> {
   const [ready, setReady] = useState(false);
   const [amogeLoggedIn, setAmogeLoggedIn] = useState(false);
   const [info, setInfo] = useState<T>(emptyInfo as T);

   const updateSessionInfo = () => AmogeServer<T>("/amoge/api/session-info").then(response => {
      setReady(true);
      setInfo(response);
      setAmogeLoggedIn(response.userTypes.indexOf("AmogeSysAdmin") >= 0);
   });


   useEffect(() => {
      updateSessionInfo().then(() => {});
   }, [])

   const userCanDo = role => (info?.roles || []).indexOf(role) >= 0;

   const userCanDoAny = roles => {
      let result = false;
      for (let j = 0; j < roles.length && !result; j++) {
         result = userCanDo(roles[j]);
      }
      return result;
   }

   const userCanDoAll = roles => {
      let result = true;
      for (let j = 0; j < roles.length && result; j++) {
         result = userCanDo(roles[j]);
      }
      return result;
   }

   const reload = () => updateSessionInfo();

   return {
      info,
      ready,
      amogeLoggedIn,
      reload,
      userCanDo,
      userCanDoAll,
      userCanDoAny
   };
}
