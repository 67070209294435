import * as React from "react";

export type ImagicianSourcesProps = {
   galleryName: string,
   imageName: string,
   types?: string[],
   dimensions?: { h?: number, w?: number }[],
   sizes?: string[],
   quality?: number
}

export default function ImagicianSources(props: ImagicianSourcesProps) {
   const types = props.types || ["webp", "jpg"];
   const dimensions = props.dimensions || [{w: 480}, {w: 1080}, {w: 1440}];
   const sizes = props.sizes || [
      "(max-width: 600px) 480px",
      "(max-width: 1200px) 1080px",
      "1440px"
   ]
   const quality = props.quality || 65

   const imagicianQueryParams = (fmt: string, dimension: {w?: number, h?: number}, quality: number) => {
      const queryParams: string[] = [];
      if (fmt) {
         queryParams.push(`fmt=${fmt}`)
      }
      if (dimension.w) {
         queryParams.push(`maxw=${dimension.w}`);
      }
      if (dimension.h) {
         queryParams.push(`maxh=${dimension.h}`);
      }
      if (quality) {
         queryParams.push(`q=${quality}`)
      }
      return "?" + queryParams.join("&");
   }

   function srcSet(type) {
      return dimensions.map(dimension => {
         const queryParams = imagicianQueryParams(type, dimension, quality);
         let entry = `/amoge/media/${props.galleryName}/images${props.imageName}${queryParams}`
         if (dimension.w) {
            entry += ` ${dimension.w}w`
         }
         return entry;
      }).join(",");
   }

   const src = () => {
      const lastDimension = dimensions[dimensions.length - 1];
      const queryParams = imagicianQueryParams(null, lastDimension, quality);
      return `/amoge/media/galleries/${props.galleryName}/images${props.imageName}${queryParams}`;
   }

   return <>
      {types.map(type => <source key={type}
                                 type={`image/${type}`}
                                 srcSet={srcSet(type)}
                                 src={src()}
                                 sizes={sizes.join(",")}/>)}
   </>
}