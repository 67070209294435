import useMenus from "./useMenus";
import {PaatsiSession, PaatsiSessionContext, PaatsiSessionInfo} from "@unclejust/paatsi-client/src/utils/SessionInfo";
import confirmInstagramVisit from "./InstagramLinkConfirm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-regular-svg-icons";
import {useState} from "react";

export default function NavMenuBar() {
   const {menuCss, bookingEnabled} = useMenus();

   const BookNowMenuItem = () => <>
      {bookingEnabled && <a href="/book-now.html" className={"book-now " + menuCss('/book-now')}>Book Now</a>}
   </>

   return <nav className="nav-menubar">
      <PaatsiSessionContext.Consumer>
         {session => <>
            <div className="width-control wide">
               <div>
                  <a href="/index.html" className={menuCss('/home')}>Home</a>
                  <a href="" onClick={confirmInstagramVisit} className={menuCss('/gallery')}>Instagram</a>
                  <a href="/treatment-info.html" className={menuCss('/treatment-info')}>Treatments</a>
                  <a href="/pricing.html" className={menuCss('/pricing')}>Pricing</a>
                  <a href="/about-us.html" className={menuCss('/about-us')}>About Us</a>
                  <a href="/contact-us.html" className={menuCss('/contact-us')}>Contact Us</a>
                  {!session.loggedIn && <>
                     <a href="/login.html" className={menuCss('/login')}>Log In</a>
                     <BookNowMenuItem/>
                  </>}
               </div>
            </div>
            <div className="width-control narrow">
               <div>
                  <a href="/index.html" className={menuCss('/home')}>Home</a>
                  <a href="treatment-info.html" className={menuCss('/treatment-info')}>Treatments</a>
                  <a href="" onClick={confirmInstagramVisit} className={menuCss('/gallery')}>Instagram</a>
                  <a href="pricing.html" className={menuCss('/pricing')}>Pricing</a>
                  {!session.loggedIn && <BookNowMenuItem/>}
               </div>
            </div>
            {session.loggedIn && <>
               <div className="user-menu wide">
                  {session.agentLoggedIn && <div>
                     <a href="/agent/dashboard.html" className={menuCss('/agent/dashboard')}>Dashboard</a>
                     <a href="/paatsi/agent/clients.html" className={menuCss('/paatsi/agent/inventory')}>Clients</a>
                     <a href="/paatsi/agent/inventory.html" className={menuCss('/paatsi/agent/inventory')}>Inventory</a>
                     <a href="/paatsi/agent/profile.html" className={menuCss('/paatsi/agent/profile')}>Profile</a>
                     <a href="/paatsi/agent/price-plans.html" className={menuCss('/paatsi/agent/price-plans')}>Price Plans</a>
                     <a href="/paatsi/agent/schedule.html" className={menuCss('/paatsi/agent/schedule')}>Schedule</a>
                     <a href="/paatsi/agent/treatments.html" className={menuCss('/paatsi/agent/treatments')}>Treatments</a>
                     <a href="/amoge/api/logout">Log Out</a>
                  </div>}
                  {session.clientLoggedIn && <div>
                     <a href="/client/profile.html" className={menuCss('/client/profile')}>Profile</a>
                     <a href="/client/appointments.html" className={menuCss('/client/appointments')}>Appointments</a>
                     {bookingEnabled && <a href="/book-now.html" className={"book-now " + menuCss('/book-now')}>Book Now</a>}
                     <a href="/amoge/api/logout">Log Out</a>
                  </div>}
                  {session.sysAdminLoggedIn && <div>
                     <a href="/paatsi/admin/dashboard.html" className={menuCss('/paatsi/admin/dashboard')}>Dashboard</a>
                     <a href="/amoge/api/logout">Log Out</a>
                  </div>}
               </div>
               <div className="user-menu narrow">
                  {session.agentLoggedIn && <div>
                     <a href="/agent/dashboard.html" className={menuCss('/agent/dashboard')}>Dashboard</a>
                     <a href="/paatsi/agent/clients.html" className={menuCss('/paatsi/agent/inventory')}>Clients</a>
                     <a href="/paatsi/agent/inventory.html" className={menuCss('/paatsi/agent/inventory')}>Inventory</a>
                     <a href="/paatsi/agent/schedule.html" className={menuCss('/paatsi/agent/schedule')}>Schedule</a>
                     <a href="/amoge/api/logout">Log Out</a>
                  </div>}
                  {session.clientLoggedIn && <div>
                     <a href="/client/profile.html" className={menuCss('/client/profile')}>Profile</a>
                     <a href="/client/appointments.html" className={menuCss('/client/appointments')}>Appointments</a>
                     {bookingEnabled && <a href="/book-now.html" className={"book-now " + menuCss('/book-now')}>Book Now</a>}
                     <a href="/amoge/api/logout">Log Out</a>
                  </div>}
                  {session.sysAdminLoggedIn && <div>
                     <a href="/paatsi/admin/dashboard.html" className={menuCss('/paatsi/admin/dashboard')}>Dashboard</a>
                     <a href="/amoge/api/logout">Log Out</a>
                  </div>}
               </div>
            </>}
         </>}
      </PaatsiSessionContext.Consumer>
   </nav>
}