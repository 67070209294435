import {useEffect, useRef, useState} from "react";
import {ActionLinkGroup} from "./ActionLinkGroup";
import ActionLink from "./ActionLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCogs} from "@fortawesome/free-solid-svg-icons";
import AmogeServer from "../utils/AmogeServer";
import {AmogeSessionContext} from "../utils/SessionInfo";
import {Slide} from "./SlideEditor";
import * as React from "react";
import ImagicianSources from "./ImagicianSources";

export type SlideshowProps = {
   id: string,
   galleryName: string,
   configUri?: string,
   configEditorUri?: string,
   editable?: boolean,
   quality?: number
}

export type SlideshowConfig = {
   height: string,
   title: string,
   galleryName: string,
   slides: Slide[],
   shuffle: boolean
}

export default function Slideshow(props: SlideshowProps) {
   const elem = useRef(null);
   const cycle = useRef(0);
   const index = useRef(0);
   const firstTime = useRef(true);

   const [title, setTitle] = useState(undefined);
   const [visible, setVisible] = useState<boolean>(false);
   const [galleryName, setGalleryName] = useState(props.galleryName);
   const [slides, setSlides] = useState([]);
   const [orientation, setOrientation] = useState("");
   const [loaded, setLoaded] = useState(false);

   useEffect(() => {
      load()
   }, []);

   useEffect(() => {
      if (loaded) {
         return run();
      }
   }, [loaded])

   const slideStyle = (slide): React.CSSProperties => {
      let pos = slide.positions[orientation];
      let position = `${pos.x}% ${pos.y}%`;
      return {
         objectFit: "cover",
         objectPosition: position
      }
   }

   function shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
         let j = Math.floor(Math.random() * (i + 1));
         [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
   }

   const load = () => {
      let uri = props.configUri || "/amoge/api/slideshows/config"
      AmogeServer<SlideshowConfig>(`${uri}/${props.id}`, null, false).then(response => {
         setTitle(response.title);
         setGalleryName(prev => prev || response.galleryName);
         setSlides(response.shuffle ? shuffle(response.slides) : response.slides)
         setLoaded(true);
         setVisible(true);
      })
   };

   const run = () => {
      const timeout = setTimeout(() => changeSlide(), 0);
      const interval = setInterval(() => changeSlide(), 2500);
      return () => {
         clearTimeout(timeout);
         clearInterval(interval);
      }
   };

   const changeSlide = () => {
      updateOrientation();
      let slides = elem.current.querySelectorAll(".slideshow");
      cycle.current = (cycle.current + 1) % 2;
      if (slides.length && cycle.current && !firstTime.current) {
         let currentIndex = index.current || 0;
         let nextIndex = currentIndex + 1;
         if (nextIndex === slides.length) {
            nextIndex = 0;
         }

         slides[currentIndex].style.opacity = "0";
         slides[nextIndex].style.opacity = "1";
         index.current = nextIndex;
      }
   };

   const updateOrientation = () => {
      let width = elem.current.clientWidth;
      let height = elem.current.clientHeight;

      if (width < height) {
         setOrientation("portrait");
      } else if (width === height) {
         setOrientation("square");
      } else if (width > height && width < 2 * height) {
         setOrientation("landscape");
      } else {
         setOrientation("panorama");
      }
   };

   const edit = () => {
      let editorUri = props.configEditorUri || "/amoge/admin/tools/slideshow-editor.html";
      window.location.href = `${editorUri}?id=${props.id}&galleryName=${galleryName}&page=${window.location.pathname}`;
   };

   const imgLoaded = (evt, i) => {
      // Show the first image to load as soon as possible
      if (firstTime.current) {
         firstTime.current = false;
         evt.target.closest(".slideshow").style.opacity = "1";
         index.current = i;
      }
   }

   const slideImgSrc = (slide: Slide) => {
      let src = `/amoge/media/${galleryName}/images${slide.path}?fmt=jpg`;
      if (props.quality) {
         src += `&q=${props.quality}`;
      }
      return src;
   }

   return <div ref={elem} className="slideshow-container" id={`slideshow-${props.id}`}>
      {visible && <>
         {title && orientation && <div>
            <div className="slideshow-text-container">
               <div className="slideshow-text">{title}</div>
            </div>
         </div>}
         <div className="slideshow-slide-container">
            {slides.map((slide, i) => <div key={i} className="slideshow">
               {orientation && <div className="slideshow-img">
                  <picture>
                     <ImagicianSources galleryName={galleryName} imageName={slide.path} quality={props.quality}/>
                     <img src={slideImgSrc(slide)} style={slideStyle(slide)} onLoad={evt => imgLoaded(evt, i)}/>
                  </picture>
               </div>}
            </div>)}
         </div>
         <AmogeSessionContext.Consumer>
            {session => <>
               {(props.editable || session.userCanDo("SLIDESHOW_EDITOR")) && <div className="slideshow-editor-container">
                  <ActionLinkGroup>
                     <ActionLink action={edit}>
                        <FontAwesomeIcon icon={faCogs}/>
                     </ActionLink>
                  </ActionLinkGroup>
               </div>}
            </>}
         </AmogeSessionContext.Consumer>
      </>}
   </div>
}
